import { Button, DatePickerInput } from 'pepsico-ds';
import './employee.css';

const EmployeeRequest = () => {

    return (
        <div id="employeeRequest">
            <div className="card employeeCard">
                <div className="card-content">Non-Employee Extension</div>
            </div>
            <div className='d-flex' style={{ gap: '12px' }}>
                <div className="card request-section">
                    <div className="card-content" style={{ display: 'grid' }}>
                        <div className='secondary-text font-700' style={{ fontSize: '18px' }}>
                            Extension request for Self
                        </div>
                        <div className='link font-700 mt-4'>Associate Info</div>
                        <div className='d-flex w-100 justify-content-between mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }}>
                                <div className='detailsValue font-700'>Maintainence Type</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>First Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>Middle Name</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.3%' }}>
                                <div className='detailsValue font-700'>Last Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                        </div>

                        <div className='link font-700 mt-4'>Job Info</div>
                        <div className='d-flex w-100 justify-content-start mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }} >
                                <div className='detailsValue font-700'>Manager</div>
                                <div className='detailInfo'>Consent</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.33%' }}>
                                <div className='detailsValue font-700'>Start Date</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '46.66%' }}>
                                <DatePickerInput
                                    feedbackType="info"
                                    helperText=""
                                    inputSize="small"
                                    label="Expiration Date"
                                    placeholder="mm/dd/yyyy"
                                    required
                                    selected="06/06/2024"
                                    setSelected={function Qa() { }}
                                />
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-start mt-4' style={{ gap: '12px' }}>
                            <Button size="medium" text="Save Changes" variant="primary" iconLeading='check' />
                            <Button size="medium" text="Discard" variant="secondary" iconLeading='close' />
                        </div>
                    </div>
                </div>
                <div className="card request-section">
                    <div className="card-content" style={{ display: 'grid' }}>
                        <div className='secondary-text font-700' style={{ fontSize: '18px' }}>
                            Extension request for others
                        </div>
                        <div className='link font-700 mt-4'>Associate Info</div>
                        <div className='d-flex w-100 justify-content-between mt-2'>

                            <div>
                                <div className='detailsValue font-700'>Maintainence Type</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>First Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>Middle Name</div>
                                <div className='detailInfo'>John Doe</div>
                            </div>
                            <div>
                                <div className='detailsValue font-700'>Last Name</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                        </div>

                        <div className='link font-700 mt-4'>Job Info</div>
                        <div className='d-flex w-100 justify-content-start mt-2'>
                            <div className='dynamic-width' style={{ '--dynamic-width': '30%' }} >
                                <div className='detailsValue font-700'>Manager</div>
                                <div className='detailInfo'>Consent</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '23.33%' }}>
                                <div className='detailsValue font-700'>Start Date</div>
                                <div className='detailInfo'>10/10/2021</div>
                            </div>
                            <div className='dynamic-width' style={{ '--dynamic-width': '46.66%' }}>
                                <DatePickerInput
                                    feedbackType="info"
                                    helperText=""
                                    inputSize="small"
                                    label="Expiration Date"
                                    placeholder="mm/dd/yyyy"
                                    required
                                    selected="06/06/2024"
                                    setSelected={function Qa() { }}
                                />
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-start mt-4' style={{ gap: '12px' }}>
                            <Button size="medium" text="Save Changes" variant="primary" iconLeading='check' />
                            <Button size="medium" text="Discard" variant="secondary" iconLeading='close' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmployeeRequest;