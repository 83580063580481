import './App.css';
import { Route, Routes } from 'react-router'
import { BrowserRouter, Navigate } from "react-router-dom"

import { Security, LoginCallback, SecureRoute } from "@okta/okta-react"
import { OktaAuth } from "@okta/okta-auth-js"
// import Languages from './Components/Appconfig/Languages/Languages';
// import DispLang from './Components/Appconfig/Languages/DisplayLang';
// import Sidebar from './Components/Common/Sidebar/Sidebar';
// import Header from './Components/Common/Header/Header';
// import PageNotFound from './Components/Common/PageNotFound/PageNotFound';

// import SimpleDialog from './Components/d-DashBoard/SimpleDialog';

import store from './Redux/store';
import Login from './auth/login';
import Home from './Components/landing/home';
import ForgotPassword from './auth/forgot-password';
import Registration from './auth/registration';
import LoginCallBack from './auth/LoginCallBack';
import { FontSizeProvider } from './common/fontSizeContext';
import Welcome from './Components/landing/welcome';
import httpInterceptor from './Interceptor/http-interceptor';
import React, { useEffect, useState } from 'react';
import HeaderView from './common/header-view';
import HeaderPublic from './common/header-public';
import i18n from './common/i18n';
import Changepassword from './auth/change_password';
import TopMenuBar from './common/top_menu_bar';
import { ThemeProvider } from './common/themeContext';
import Settings from './common/settings';
import { MenuProvider, useMenu } from './common/menuContext';
import SidebarView from './common/sidebar';
import PrivateRoute from './auth/private_route';
import PendingApprovals from './Components/workflow/pending_approval';
import HomeView2 from './Components/landing/home-view2';
import WorkflowAction from './Components/workflow/workflow_action';
import EmployeeRequest from './Components/manage_accounts/employee_request/employee_request';
import ManageAccounts from './Components/manage_accounts/employee_request/manage_accounts';
import RejectedApplications from './Components/workflow/rejected_applications';
httpInterceptor.httpinterceptor(store)
const MenuDependentContent = () => {
  const { menuLayout } = useMenu();
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log('New Data');
    const userAuthenticated = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
    if (userAuthenticated || window.location.hostname.includes('localhost')) {
      setIsAuthenticated(true); // Set logged-in status based on token presence
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>

      <div style={{ position: 'fixed', width: '-webkit-fill-available', zIndex: 1000 }}>
        {((!isAuthenticated || window.location.pathname.includes('/public')) ? <HeaderPublic /> : <HeaderView />)}
      </div>
      <div style={{ display: 'flex' }}>
        <div className='sidebar_align'>
          {window.location.pathname.includes('/public') ? null : <SidebarView />}
        </div>
        <div className='main-content'>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/public/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/public/registration" element={<Registration />} />
             <Route exact path="/homev2" element={<PrivateRoute isAuthenticated={isAuthenticated}><Home /></PrivateRoute>} />
            <Route exact path="/home" element={<PrivateRoute isAuthenticated={isAuthenticated}><HomeView2 /></PrivateRoute>} />
            <Route exact path="/welcome" element={<Welcome />} />
            {/* <Route exact path="/change-password" element={<Changepassword />} /> */}
            <Route path="/change-password" element={<PrivateRoute isAuthenticated={isAuthenticated}><Changepassword /></PrivateRoute>} />
            <Route path="/approvals" element={<PrivateRoute isAuthenticated={isAuthenticated}><PendingApprovals /></PrivateRoute>} />
            <Route path="/rejectedApplications" element={<PrivateRoute isAuthenticated={isAuthenticated}><RejectedApplications /></PrivateRoute>} />
            <Route path="/workflow" element={<PrivateRoute isAuthenticated={isAuthenticated}><WorkflowAction /></PrivateRoute>} />
            <Route path="/manageAccounts" element={<PrivateRoute isAuthenticated={isAuthenticated}><ManageAccounts /></PrivateRoute>} />
            <Route path="/extensionRequest" element={<PrivateRoute isAuthenticated={isAuthenticated}><EmployeeRequest /></PrivateRoute>} />
            <Route path="/dashboard" element={<LoginCallBack />} />
            <Route path="/logincallback" element={<LoginCallBack />} />
            <Route path="/public/registration" element={<Registration />} />
            {/* <Route path="/launch-pad/*" element={<Home />} /> */}
            <Route path="/public/forgot-password/*" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />


            {/* <Route exact path="/unauthorised"> <Unauthororised /></Route> */}
            {/* <Route exact path="/authenticate" component={LoginCallback} /> */}
            <Route exact path="/authenticate" element={<LoginCallback />} />
            <Route path="*" element={<Navigate to={isAuthenticated ? window.location.pathname : '/login'} />} />

            {/* <SecureRoute exact path="*">
            <PageNotFound />
          </SecureRoute> */}
          </Routes>
        </div>
      </div>
    </div >
  );
};

function App() {
  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { REACT_APP_CLIENT_ID, REACT_APP_ISSUER, REACT_APP_REDIRECT_URI, REACT_APP_POST_REDIRECT_URI } = process.env
  const clientId = REACT_APP_CLIENT_ID
  const issuer = REACT_APP_ISSUER
  const redirectUrl = REACT_APP_REDIRECT_URI
  const postLogoutUrl = REACT_APP_POST_REDIRECT_URI
  const oktaAuth = new OktaAuth({
    issuer: issuer,
    clientId: clientId,
    redirectUri: redirectUrl,
    postLogoutRedirectUri: postLogoutUrl,
    pkce: true,
    onAuthRequired: true,
    scopes: [
      "profile",
      "email",
      "openid",
      "offline_access"
    ]
  });

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);
  // console.log(window.location.pathname.includes('/public'))
  const restoreOriginalUri = async () => {
    window.location.href = window.location.origin + '/home'
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
        <FontSizeProvider>
          <ThemeProvider>
            <MenuProvider>
              <MenuDependentContent />
              <Settings />


            </MenuProvider>
          </ThemeProvider>
        </FontSizeProvider>
      </Security>
    </BrowserRouter>
  );
}

export default App;
