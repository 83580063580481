import assignment_ind from '../../../assets/icons/accounts-icons/assignment_ind.png';
import FormAttributes from '../../workflow/test';
import './employee.css';

const ManageAccounts = () => {
    return (
        <div id="mangeAccounts" >
            <div className='card accountCard'>
                <h3>Manage Accounts</h3>
                <div className="accountContent card-content">
                    <div className='d-flex accountLayout'>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                        <div className="card accountReqCard">
                            <div className="card-content">
                                <div>
                                    <img src={assignment_ind} alt="Gpid" />
                                    <div className="cardLabel">GPID Request for a non-employe</div>
                                    <div className="detailsValue">I’m the description for this card</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormAttributes />
            </div>
        </div>
    );
}

export default ManageAccounts;