// AgGridComponent.jsx
import React, {  forwardRef, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';


const AgGridComponent = forwardRef (({ rowData = [], columnDefs = [], defaultColDef = null, rowSelection = {},checkedIndexes = [], pagination = true,
    paginationPageSize = 10, gridHeight = 500, theme = 'ag-theme-quartz', additionalGridProps = {},sendDataToApprovals }, ref) => {

       useImperativeHandle(ref, () => ({
            getSelectedRowData,
            selectAllCheckBoxes(){
                if (gridApi) {
                    gridApi.selectAll();
                }
              },
              clearAllCheckBoxes(){
                if (gridApi) {
                    gridApi.deselectAll();
                }
              },            
              clearAllFilters(){
                if (gridApi) {
                    gridApi.setFilterModel(null);
                }
              },
              countSelected,  
           }));
         
         const getSelectedRowData = ()=>{
            if (gridApi) {
              const selectedNodes = gridApi.getSelectedNodes();
              const selectedIndexes = selectedNodes.map(node => node.rowIndex);
              return selectedIndexes;
            } 
         }       
        const countSelected = () => {
            if (gridApi) {
                const selectedNodes =  gridApi.getSelectedNodes();
                const selectedLength = selectedNodes.length;
                return selectedLength;
             }
             return 0;
          };

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            sendDataToApprovals( selectedNodes);
        };
        const [gridApi, setGridApi] = useState(null);

        const defaultColumnDefs = useMemo(() => {
        return (
            defaultColDef || {
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                resizable: true,
            }
        );
    }, [defaultColDef]);

    const onGridReady = (params) => {
        setGridApi(params.api);
         params.api.forEachNode((node, index) => {
            if (checkedIndexes.includes(index)) {
               node.setSelected(true);
            }
            else
            {
                node.setSelected(false);
            }
        });  
    };

    return (
        <div className={theme} style={{ height: gridHeight }}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowSelection={rowSelection}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                onGridReady={onGridReady}
                {...additionalGridProps}
                onSelectionChanged={onSelectionChanged}

            />
        </div>
    );
});

export default AgGridComponent;
