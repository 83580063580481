import { Button, Filter, Icon, Search, Tag, Dropdown } from 'pepsico-ds';
import React, { useState, useRef, useEffect } from 'react';
import './workflow.css';
import AgGridComponent from '../../common/ag_grid';
import { Route, Routes, useNavigate } from 'react-router-dom';

const PendingApprovals = () => {
    useEffect(() => {
        if(gridToCard)
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            //       const checkedIndexes = checkedItems.map(item => item.index);
                checkboxes.forEach((checkbox, index) => {
                    if (checkedIndexes.includes(index)) {
                        checkbox.checked = true;
                    } else {
                        checkbox.checked = false;
                    }
                });
            setGridToCard(false);    
        }
    });

    const agGridRef = useRef();
    const navigate = useNavigate();
    const [selectedCount, setSelectedCount] = useState(0);
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [gridToCard, setGridToCard] = useState(false);
    const [rowData] = useState([
        { requestNumber: '01159421', requestType: 'ISUM Exception Queue 1', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '11/20/2024' },
        { requestNumber: '01159422', requestType: 'ISUM Exception Queue 2', requester: 'Doe', requestedDate: '10/10/2021', dueDate: '12/5/2024' },
        { requestNumber: '01159423', requestType: 'ISUM Exception Queue 3', requester: 'John Doe 1', requestedDate: '10/10/2021', dueDate: '12/25/2024' },
        { requestNumber: '01159424', requestType: 'ISUM Exception Queue 4', requester: 'Doe John', requestedDate: '10/10/2021', dueDate: '12/20/2024' },
        { requestNumber: '01159425', requestType: 'ISUM Exception Queue 5', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '12/30/2024' },
        { requestNumber: '01159426', requestType: 'ISUM Exception Queue 6', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '12/12/2024' },
    ]);
    const rowSelection = {
        mode: 'multiRow',
        headerCheckbox: true,
    };
    const [columnDefs] = useState([
        { headerName: "Request #", field: "requestNumber" },
        { headerName: "Request Type", field: "requestType" },
        { headerName: "Requester", field: "requester" },
        { headerName: "Requested Date", field: "requestedDate" },
        { headerName: "Due Date", field: "dueDate" },
        { headerName: "Actions", field: "actions" }
    ]);
    const [isGridType, setIsGridType] = React.useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    setSearchTerm(searchText);
  };

  const filteredItems = rowData.filter((item) => {
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
   const getDataFromGrid = (nodes) =>
    {
        setSelectedCount(nodes.length);
    };
    const setDisplayType = (type) => {
        setIsGridType(type === 'GRID' ? true : false);
          if(type === 'GRID' )
          {   
            setCheckedIndexes(agGridRef.current.getSelectedRowData());
            setGridToCard(true);
          }
          else
          {
            setGridToCard(false);
          }
   }

    const displaySelectedRows = () => {
        if (agGridRef.current) {
            agGridRef.current.getSelectedRowData();
        }
      };

      const onSelectAll = () => {
        if (agGridRef.current) {
            agGridRef.current.selectAllCheckBoxes();
            setSelectedCount(agGridRef.current.countSelected());
        }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox,index) => {
              checkbox.checked = true;
                 setCheckedIndexes((prevIndexes) => {
                   return [...prevIndexes, index];
                });            
            });
            setSelectedCount(checkboxes.length);

        }
      };

      const onClearAll = () => {
        setSelectedCount(0);
        setSearchText('');
        setSearchTerm('');
        if (agGridRef.current) {
            agGridRef.current.clearAllCheckBoxes();
            agGridRef.current.clearAllFilters();
         }
        else
        {
            const checkboxes = document.querySelectorAll('.cardCheckBox');
            checkboxes.forEach((checkbox) => {
              checkbox.checked = false;
            });
            setCheckedIndexes([]);
        }
      };
    
      const onCheckboxChange = (event,index) => {
        setCheckedIndexes((prevIndexes) => {
            if (event.target.checked) {
                return [...prevIndexes, index];
            } else {
                return prevIndexes.filter((i) => i !== index);
             }
        });
    
        const checkboxes = document.querySelectorAll('.cardCheckBox');
        const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
        setSelectedCount(checkedCount);
    };
    
    const startWorkflow = () => {
        navigate('/workflow');
    }
    return (
        <div id="pendingApprovals">
            <span className="page-header">Pending Approvals</span>

            <div className='filters'>
               <div className='d-flex'>
                    <Icon icon="grid_on" className={`${isGridType === true ? 'link' : ''}`} onClick={() => setDisplayType('GRID')} />
                    <Icon icon="list_alt" className={`${isGridType === false ? 'link' : ''}`} onClick={() => setDisplayType('LIST')} />
                </div>
                <div className='d-flex'>
                    <Search
                        onSearch={handleSearch}
                        onSelectOption={function Qa() { }}
                        onUpdate={(value) => setSearchText(value)}
                        placeholderText="Type a search keyword"
                        size="medium" />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'Today'
                            },
                            {
                                id: '2',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'Today'
                        }}
                    />

                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'This Week'
                            },
                            {
                                id: '2',
                                label: 'Next Week'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'This Week'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'This Month'
                            },
                            {
                                id: '2',
                                label: 'Next Month'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'This Month'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'All Time'
                            },
                            {
                                id: '2',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'All Time'
                        }}
                    />
                    <span onClick={onClearAll} className='link bold-text'>Clear All</span>
                </div>
            </div>
             <div  className="parent">
                <div className="vertical-group" style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5 className="child" style={{ fontWeight: 'bold' }}>Bulk Actions</h5>
                    <span className='bold-text' style={{ marginTop: '10px' }}>{selectedCount} item(s) selected</span>
                </div>                
                <button className="child" onClick={onSelectAll} style={{ color: '#005cbc', background: 'none', border: 'none', cursor: 'pointer', fontWeight: 'bold'}}>
                    Select all Items
                </button> 
                <div className="ribbon child">
                    <div className="ribbon-group">
                        <button className="ribbon-button" onClick={() => alert('Approved')}>Approve</button>
                        <button className="ribbon-button" onClick={() => alert('Rejected')}>Reject</button>
                        <button className="ribbon-button" onClick={() => alert('Reserved')}>Reserve</button>
                    </div>
                </div>  
             </div>
            {isGridType && (
                <div className='d-flex'>
                    <div className='cards-container'>
                        {filteredItems.map((card, index) => (
                            <div className='card approvalCard' key={index}>
                                <div className='card-content'>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div className='approvalHeader'  onClick={()=> startWorkflow()}>{card.requestType}</div>
                                        <Icon icon="more_vert" />
                                    </div>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <span>Req # {card.requestNumber}</span>
                                        <input className="cardCheckBox" type="checkbox"  onClick={(event) => onCheckboxChange(event,index)} style={{ marginLeft: '10px' }} />
                                    </div>
                                    <div className='mt-2'>
                                        <Tag
                                            color="Orange-400"
                                            iconLeading="warning"
                                            onRemove={function Qa() { }}
                                            size="large"
                                            text="Label"
                                            type="filled"
                                        />
                                    </div>
                                    <div className='d-flex w-100 mt-2' style={{ gap: '12px' }}>
                                        <Icon icon='calendar_today' className='link' />
                                        <span className='detailsValue'>Last Update</span>
                                        <span className='detailsValue'>{card.requestedDate}</span>
                                    </div>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div>
                                            <div className='detailsLabel'>Delegated From</div>
                                            <div className='detailsValue'>{card.requester}</div>
                                        </div>
                                        <div style={{ border: 'solid 1px #e5e5e5' }}></div>
                                        <div>
                                            <div className='detailsLabel'>Initiated By</div>
                                            <div className='detailsValue'>10/10/2021</div>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 justify-content-end mt-4'>
                                        <Button size="small" text="Reject" variant="primaryInverse" iconLeading='close' />
                                        <Button size="small" text="Approve" variant="secondary" iconLeading='check' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!isGridType && (
                <div className='table-container'>

                    <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={filteredItems}
                        rowSelection={rowSelection}
                        ref={agGridRef}
                        checkedIndexes={checkedIndexes}
                        sendDataToApprovals = {getDataFromGrid}
                    />
                </div>
            )}
        </div>
    )
};
export default PendingApprovals;