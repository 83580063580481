import { Button, Dropdown, Icon, Tab, TabsNavigation, Tag, TextArea } from 'pepsico-ds';
import './workflow.css';
import AgGridComponent from '../../common/ag_grid';
import { useState } from 'react';

const WorkflowAction = (props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [rowData] = useState([
        { requestNumber: '01159421', requestType: 'ISUM Exception Queue 1', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159422', requestType: 'ISUM Exception Queue 2', requester: 'Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159423', requestType: 'ISUM Exception Queue 3', requester: 'John Doe 1', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159424', requestType: 'ISUM Exception Queue 4', requester: 'Doe John', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159425', requestType: 'ISUM Exception Queue 5', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159426', requestType: 'ISUM Exception Queue 6', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
    ]);

    const [columnDefs] = useState([
        { headerName: "Request #", field: "requestNumber" },
        { headerName: "Request Type", field: "requestType" },
        { headerName: "Requester", field: "requester" },
        { headerName: "Requested Date", field: "requestedDate" },
        { headerName: "Due Date", field: "dueDate" },
        { headerName: "Actions", field: "actions" }
    ]);
    const selectedTab = (index) => {
        console.log(index);
        setSelectedTabIndex(index)
    }
    return (
        <div style={{ gap: '12px' }} id='workflowAction'>
            <div className='d-flex' style={{ gap: '12px' }}>
                <div style={{ display: 'grid', alignContent: 'space-between', width: '21%' }}>
                    <div className="card workflowAppCard">
                        <div className='card-content'>
                            <div className="detailsValue">Application access request:</div>
                            <div className="approvalHeader">ULTIMUS MATERIAL Def Ingredientes_Raw Materials Approver</div>
                            <div className='mt-2' style={{ display: 'grid' }}>
                                <span className='detailsLabel' style={{ fontWeight: '700' }}>Request Number</span>
                                <span className='detailsLabel'> # 80909093 (AR - 130333144)</span>
                            </div>
                        </div>
                    </div>

                    <div className="card workflowAppCard" style={{ height: '80px' }}>
                        <div className='card-content'>
                            <Tag
                                color="Cyan-500"
                                onRemove={function Qa() { }}
                                size="large"
                                text="New Application"
                                type="filled"
                                iconLeading='info'
                            />
                            <div style={{ fontSize: '12px' }}>
                                Budget and knowledge management Hub
                            </div>
                        </div>
                    </div>

                    <div className="card workflowAppCard" style={{ height: '212px' }}>
                        <div className='card-content'>
                            <div style={{ display: 'grid', gap: '16px' }}>
                                <div>
                                    <div className='detailsLabel' style={{ fontWeight: '700' }}>
                                        item State
                                    </div>
                                    <div className='detailsLabel'>
                                        Available
                                    </div>
                                </div>
                                <div>
                                    <div className='detailsLabel' style={{ fontWeight: '700' }}>
                                        Approval Policy Description
                                    </div>
                                    <div className='detailsLabel'>
                                        Reserved Item
                                    </div>
                                </div>
                                <div>
                                    <div className='detailsLabel' style={{ fontWeight: '700' }}>
                                        Initated By
                                    </div>
                                    <div className='detailsLabel'>
                                        Ana Cardoso
                                    </div>
                                </div>
                                <div>
                                    <div className='detailsLabel' style={{ fontWeight: '700' }}>
                                        Created On
                                    </div>
                                    <div className='detailsLabel'>
                                        Aug 12, 2024    12:16 AM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ width: '56%' }}>
                    <div className="card workflowBasicCard">
                        <div className='card-content'>
                            <div>Basic Info</div>
                            <div className='d-flex' style={{ gap: '12px' }}>
                                <Dropdown
                                    childList={[
                                        {
                                            displayText: 'Security Questions',
                                            id: 'security',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Text',
                                            id: 'otp',
                                            isBadge: false
                                        }, {
                                            displayText: 'Call',
                                            id: 'otps',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Email',
                                            id: 'email',
                                            isBadge: false
                                        },
                                    ]}
                                    dropdownHeader="Select"
                                    helperText=""
                                    isRequired
                                    label="Business Role"
                                    selection="single"
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="medium"
                                    width={'100px'}
                                />
                                <Dropdown
                                    childList={[
                                        {
                                            displayText: 'Security Questions',
                                            id: 'security',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Text',
                                            id: 'otp',
                                            isBadge: false
                                        }, {
                                            displayText: 'Call',
                                            id: 'otps',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Email',
                                            id: 'email',
                                            isBadge: false
                                        },
                                    ]}
                                    dropdownHeader="Select"
                                    helperText=""
                                    isRequired
                                    label="Business Unit"
                                    selection="single"
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="medium"
                                />
                            </div>
                            <div className='d-flex' style={{ gap: '12px' }}>
                                <Dropdown
                                    childList={[
                                        {
                                            displayText: 'Security Questions',
                                            id: 'security',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Text',
                                            id: 'otp',
                                            isBadge: false
                                        }, {
                                            displayText: 'Call',
                                            id: 'otps',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Email',
                                            id: 'email',
                                            isBadge: false
                                        },
                                    ]}
                                    dropdownHeader="Select"
                                    helperText=""
                                    isRequired
                                    label="Region"
                                    selection="single"
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="medium"
                                />
                                <Dropdown
                                    childList={[
                                        {
                                            displayText: 'Security Questions',
                                            id: 'security',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Text',
                                            id: 'otp',
                                            isBadge: false
                                        }, {
                                            displayText: 'Call',
                                            id: 'otps',
                                            isBadge: false
                                        },
                                        {
                                            displayText: 'Email',
                                            id: 'email',
                                            isBadge: false
                                        },
                                    ]}
                                    dropdownHeader="Select"
                                    helperText=""
                                    isRequired
                                    label="Preference Country"
                                    selection="single"
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="medium"
                                />
                            </div>
                            <div className='d-flex mt-4'>
                                <TextArea label='Business Purpose for Application Access' />
                            </div>
                            <div className='d-flex mt-4'>
                                <TextArea label='Work History' />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '24%' }}>
                    <div className="card workflowInfoCard">
                        <div className='card-content'>
                            <TabsNavigation
                                onUpdate={(val) => selectedTab(val)}
                                selectedIndex={selectedTabIndex}>
                                <Tab label="Associate Data" />
                                <Tab label="Approval History" />
                            </TabsNavigation>
                            {selectedTabIndex === 0 &&
                                <div>
                                    <div>
                                        <Icon alt="Notifications" icon="account" size="medium" />
                                        <div className='link font-700'>Associate Info</div>
                                        <div className='d-flex' style={{ gap: '12px' }}>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Preferred Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    First Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex' style={{ gap: '12px' }}>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Middle Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Last Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className='custom-hr' />

                                    <div>
                                        <Icon alt="Notifications" icon="account" size="medium" />
                                        <div className='link font-700'>Job Info</div>
                                        <div className='d-flex' style={{ gap: '12px' }}>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Preferred Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    First Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex' style={{ gap: '12px' }}>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Middle Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                            <div>
                                                <div className='detailsLabel font-700'>
                                                    Last Name
                                                </div>
                                                <div className='detailInfo'>
                                                    content
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {selectedTabIndex === 1 &&
                                <div className='table-container'>

                                    <AgGridComponent
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        gridHeight={450}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card actionCard mt-4">
                <div className="card-content">
                    <div className='d-flex justify-content-between w-100'>
                        <div className='cardHeader'>Approve this request?</div>
                        <div className='d-flex ' style={{ gap: '30px' }}>
                            <Button size="small" text="Reserve" variant="primaryInverse" iconLeading='close' />
                            <Button size="small" text="Deny" variant="primaryInverse" iconLeading='close' />
                            <Button size="small" text="Approve" variant="primaryInverse" iconLeading='check' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkflowAction;