import { Button, Filter, Icon, Search, Tag } from 'pepsico-ds';
import React, { useState } from 'react';
import './workflow.css';
import AgGridComponent from '../../common/ag_grid';
import { Route, Routes, useNavigate } from 'react-router-dom';

const RejectedApplications = () => {
    const navigate = useNavigate();
    const [rowData] = useState([
        { requestNumber: '01159421', requestType: 'ISUM Exception Queue 1', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159422', requestType: 'ISUM Exception Queue 2', requester: 'Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159423', requestType: 'ISUM Exception Queue 3', requester: 'John Doe 1', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159424', requestType: 'ISUM Exception Queue 4', requester: 'Doe John', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159425', requestType: 'ISUM Exception Queue 5', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
        { requestNumber: '01159426', requestType: 'ISUM Exception Queue 6', requester: 'John Doe', requestedDate: '10/10/2021', dueDate: '10/10/2021' },
    ]);
    const rowSelection = {
        mode: 'multiRow',
        headerCheckbox: true,
    };
    const [columnDefs] = useState([
        { headerName: "Request #", field: "requestNumber" },
        { headerName: "Request Type", field: "requestType" },
        { headerName: "Requester", field: "requester" },
        { headerName: "Requested Date", field: "requestedDate" },
        { headerName: "Due Date", field: "dueDate" },
        { headerName: "Actions", field: "actions" }
    ]);
    const [isGridType, setIsGridType] = React.useState(true);

    const setDisplayType = (type) => {
        setIsGridType(type === 'GRID' ? true : false);
    }

    const startWorkflow = () => {
        navigate('/workflow');
    }

    return (
        <div id="pendingApprovals">
            <span className="page-header">Rejected Applications</span>

            <div className='filters'>
                <div className='d-flex'>
                    <Icon icon="grid_on" className={`${isGridType === true ? 'link' : ''}`} onClick={() => setDisplayType('GRID')} />
                    <Icon icon="list_alt" className={`${isGridType === false ? 'link' : ''}`} onClick={() => setDisplayType('LIST')} />
                </div>
                <div className='d-flex'>
                    <Search
                        onSearch={function Qa() { }}
                        onSelectOption={function Qa() { }}
                        onUpdate={function Qa() { }}
                        placeholderText="Type a search keyword"
                        size="medium" />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'Today'
                            },
                            {
                                id: '2',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'Today'
                        }}
                    />

                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'This Week'
                            },
                            {
                                id: '2',
                                label: 'Next Week'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'This Week'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'This Month'
                            },
                            {
                                id: '2',
                                label: 'Next Month'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'This Month'
                        }}
                    />
                    <Filter
                        label="Default"
                        listLabel="Header"
                        onChange={function Qa() { }}
                        options={[
                            {
                                id: '1',
                                label: 'All Time'
                            },
                            {
                                id: '2',
                                label: 'Tomorrow'
                            }
                        ]}
                        selection="single"
                        size="small"
                        value={{
                            id: '1',
                            label: 'All Time'
                        }}
                    />
                    <span className='link bold-text'>Clear All</span>
                </div>
            </div>
            {isGridType && (
                <div className='d-flex'>
                    <div className='cards-container'>
                        {rowData.map((card, index) => (
                            <div className='card approvalCard' key={index} onClick={() => startWorkflow()}>
                                <div className='card-content'>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div className='approvalHeader'>{card.requestType}</div>
                                        <Icon icon="more_vert" />
                                    </div>
                                    <div className='mt-2'>
                                        <span>Req # {card.requestNumber}</span>
                                    </div>
                                    <div className='mt-2'>
                                        <Tag
                                            color="Orange-400"
                                            iconLeading="warning"
                                            onRemove={function Qa() { }}
                                            size="large"
                                            text="Label"
                                            type="filled"
                                        />
                                    </div>
                                    <div className='d-flex w-100 mt-2' style={{ gap: '12px' }}>
                                        <Icon icon='calendar_today' className='link' />
                                        <span className='detailsValue'>Last Update</span>
                                        <span className='detailsValue'>{card.requestedDate}</span>
                                    </div>
                                    <div className='d-flex w-100 justify-content-between mt-2'>
                                        <div>
                                            <div className='detailsLabel'>Delegated From</div>
                                            <div className='detailsValue'>{card.requester}</div>
                                        </div>
                                        <div style={{ border: 'solid 1px #e5e5e5' }}></div>
                                        <div>
                                            <div className='detailsLabel'>Initiated By</div>
                                            <div className='detailsValue'>10/10/2021</div>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 justify-content-end mt-4'>
                                        <Button size="small" text="Reject" variant="primaryInverse" iconLeading='close' />
                                        <Button size="small" text="Approve" variant="secondary" iconLeading='check' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!isGridType && (
                <div className='table-container'>

                    <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={rowData}
                        rowSelection={rowSelection}
                    />
                </div>
            )}
        </div>
    )
};
export default RejectedApplications;