import { Accordion, AccordionItem, Button } from 'pepsico-ds';
import './welcome.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Welcome = ({ onSelect }) => {
    const { t, i18n } = useTranslation();
    let accessTypes = ['myidM', 'myPAM', 'myCert', 'pepAGM'];
    if (window.location.pathname.includes('homev2')) {
        accessTypes = ['myidM', 'myPAM', 'myCert', 'pepAGM', 'AD', 'SSO'];
    }
    const [activeType, setActiveType] = useState('');
    const getQuickLinksByType = (accessType) => {
        setActiveType(accessType);
        onSelect(accessType);
    }

    return (
        <div>
            <div id='welcome-container'>

                <div className="card card-layout">

                    {/* <h4>Welcome to Pepsico</h4>
                    <div className='info-container'>
                        <p>First Time Login - Registration: Please enter your Identity</p>
                    </div> */}
                    <div className='card-content'>
                        <Accordion alignment="right" onUpdate={function Qa() { }} size="large" state="enable">
                            <AccordionItem alignment="left" label={t('iamApplications')} expanded="true">
                                {/* <h5 class="text-left">IAM Applications</h5> */}
                                <div style={{ display: 'flex' }}>
                                    <div className='cards-container'>
                                        {accessTypes.map((accessType, index) => (
                                            <div key={index} className={`card idmCard ${accessType === activeType ? 'activeCard' : ''}`}>
                                                <div className='card-content' style={{ margin: 'auto' }} onClick={() => getQuickLinksByType(accessType)}>
                                                    <div className={`${accessType}_logo`}></div>
                                                    <div style={{ margin: 'auto' }}>
                                                        <a className='link bold-text'>{`Access ${accessType}`}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;