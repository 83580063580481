import React, { useEffect, useRef, useState } from 'react';
import { TextInput, Button, Checkbox, Toggle } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
// import UserValidate from './validate-user';
import OtpForm from './forgot_pwd_otp';
import forge from "node-forge";
import CryptoJS from 'crypto-js'
import SecurityForm from './forgot_pwd_security';
import Footer from '../common/footer';
import HeaderPublic from '../common/header-public';
import { useOktaAuth } from "@okta/okta-react"
import { useNavigate } from "react-router";
import './auth.css';
import Icon from 'react-icons-kit';
// import LanguageDropdown from '../common/languageDropdown';

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;
export const encrypt = (plainText, key) => {
  const publicKey = forge.pki.publicKeyFromPem(key);
  return forge.util.encode64(
    publicKey.encrypt(plainText, "RSA-OAEP", {
      md: forge.md.md5.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    })
  )
};

export const encryptAES = (msg) => {
  const key = CryptoJS.lib.WordArray.random(8).toString();
  const iv = CryptoJS.lib.WordArray.random(8).toString();

  // encrypt some bytes using GCM mode
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({
    iv: iv,
    additionalData: 'nvn', // optional
    tagLength: 128 // optional, defaults to 128 bits
  });
  cipher.update(forge.util.createBuffer(msg));
  cipher.finish();
  const encrypted = cipher.output;
  const encodedB64 = forge.util.encode64(encrypted.data);
  const tag = cipher.mode.tag;
  const tagB64 = forge.util.encode64(tag.data);
  return {
    key: encrypt(key, publicKey),
    iv: iv,
    tag: tagB64,
    encrypt: encodedB64,
  }
};

function ForgotPassword() {
  const { REACT_APP_API_ENDPOINT, REACT_APP_NAME } = process.env;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [startDisabled, setStartDisabled] = useState(true);
  // const [verifyOption, setVerifyOption] = useState('');
  const [isGpidValid, setGpidValid] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isUserValidate, setUserValidate] = useState(false);
  const [isForgotPwdOptionType, setForgotPwdOptionType] = useState(false);
  const [selectedOtp, setSelectedOtp] = useState(false);
  const [selectedCall, setSelectedCall] = useState(false);
  const [selectedSecurityQuestion, setSelectedSecurityQuestion] = useState(false);
  const [gpidErrorMessage, setGpidErrorMessage] = useState('');
  const [res, setres] = useState({});
  const [powdSuccess, setpowdSuccess] = useState(false);
  const [endpointAccounts, setEndpointAccounts] = useState(['myidM', 'IDX', 'PKI']);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isTyped, setIsTyped] = useState(false);
  const [isBg, setIsBg] = useState(false);
  const [secQnLocked, setSecQnLocked] = useState(false);
  const [secQRetryCount, setSecQRetryCount] = useState(0);
  const [passwordValue, setpaswordValue] = useState('');
  const [powdHints, setpowdHints] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasTwoNumbers: false,
    noSequentialChars: true,
    noForbiddenKeywords: true,
    noRepeatingChars: true,
    notStartWithNumber: true,
    notEndWithNumber: true,
  });

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (event) => {
    console.log(event);
    setErrorMessage('');
    const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
    if (onlyNumbers.length >= 8) {
      setStartDisabled(false);
    }
    else {
      setStartDisabled(true);
    }
    setInputValue(onlyNumbers);
  }

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye)
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  const resetTypeSelected = (type) => {
    // setVerifyOption(type);
    verifyUser(type);
    if (type === 'otp') {
      setSelectedOtp(true);
      setSelectedCall(false);
      setSelectedSecurityQuestion(false);
    }
    else if (type === 'call') {
      setSelectedCall(true);
      setSelectedOtp(false);
      setSelectedSecurityQuestion(false);
    }
    else if (type === 'security') {
      setSelectedOtp(false);
      setSelectedSecurityQuestion(true);
    }
  }

  const { register, handleSubmit, setError, clearErrors, formState: { errors, isValid }, watch } = useForm({
    mode: 'onChange',
    criteriaMode: "all"
  });
  const password = watch("password", '');

  const onConfirmation = (value) => {
    setIsConfirmed(value);
  }
  const powdValidation = (powd) => {
    // Regex pattern for the MUST requirements
    const pattern = /^(?!.*(.)\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*\d)(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)(?!^\d)(?!.*\d$)/;

    // Checking the powd against the regex pattern
    if (!pattern.test(powd)) {
      return "Password does not meet the basic criteria.";
    }

    // Additional checks for MUST NOT conditions
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];

    // Check for forbidden keywords
    for (const keyword of forbiddenKeywords) {
      if (powd.toLowerCase().includes(keyword.toLowerCase())) {
        return `Password must not contain forbidden keywords like ${keyword}`;
      }
    }

    // Check for sequential characters (abc, 123, etc.)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };

    if (isSequential(powd)) {
      return "Password must not contain sequential characters or numbers.";
    }

    return true;
  };

  const getHintColor = (conditionMet) => {
    // If user hasn't typed yet, default to gray, otherwise red or green based on condition
    return isTyped ? (conditionMet ? 'green' : 'red') : '#9e9e9e';
  };

  const powdMessageValidation = (powd) => {
    setIsTyped(true);
    setpaswordValue(powd);
    // Base regex for MUST conditions
    const basePattern = /^(?=.*[a-zA-Z]{4,})(?=.*\d{2,})(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)/;

    // Check length, letters, numbers, and special characters
    const isBaseValid = basePattern.test(powd);

    // Additional checks
    const hasLowercase = /[a-z]/.test(powd);
    const hasUppercase = /[A-Z]/.test(powd);
    const hasSpecialChar = /[!^*()_|~`{}.#\[\]:;'"<>?,]/.test(powd);
    const hasTwoNumbers = /\d.*\d/.test(powd);

    // Forbidden keywords
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];
    const noForbiddenKeywords = !forbiddenKeywords.some(keyword => powd.toLowerCase().includes(keyword.toLowerCase()));

    // Sequential characters check (abc, 123)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };
    const noSequentialChars = !isSequential(powd);

    // Repeating characters check (no more than two repeating characters)
    const noRepeatingChars = !/(.)\1{2,}/i.test(powd);

    // Check if it starts or ends with a number
    const notStartWithNumber = !/^\d/.test(powd);
    const notEndWithNumber = !/\d$/.test(powd);

    // Update validation state
    setpowdHints({
      minLength: isBaseValid,
      hasLowercase: hasLowercase,
      hasUppercase: hasUppercase,
      hasSpecialChar: hasSpecialChar,
      hasTwoNumbers: hasTwoNumbers,
      noSequentialChars: noSequentialChars,
      noForbiddenKeywords: noForbiddenKeywords,
      noRepeatingChars: noRepeatingChars,
      notStartWithNumber: notStartWithNumber,
      notEndWithNumber: notEndWithNumber,
    });
  };


  const onSubmit = (data) => {
    console.log(data);
    const powdError = powdValidation(data.password);
    if (powdError !== true) {
      setError("password", {
        type: "manual",
        message: powdError
      });
      return;
    }
    updatePassword(data);
  }


  const isUserValid = () => {
    console.log('test');
    if (inputValue.length === 8) {
      clearErrors("getChalFac");
      getChallengeFactors();
    }
    else {
      setGpidValid(false);
      setUserValidate(false);
      setError("getChalFac", {
        type: "manual",
        message: 'validGpid'
      });
    }
  };

  const getChallengeFactors = () => {
    getEndpointAccounts();
    getRetryCount();
    clearErrors("getChalFac");
    let retried = false;
    axios.post(`${REACT_APP_API_ENDPOINT}user/getChalFactors`, {
      "gpid": inputValue,
    }).then(response => {
      response['data']['gpid'] = inputValue;
      if (response?.data?.status === 'Failed') {
        setGpidErrorMessage(response?.data?.errorMessage);
        setError("getChalFac", {
          type: "manual",
          message: response?.data?.errorMessage === 'The user id is invalid or does not exist' ? 'invalidGpid' : response?.data?.errorMessage
        });
        return;
      }
      if (response?.data?.mobile) {
        setMobileNumber(response?.data?.mobile);
      }
      if (response?.data?.phoneNumber) {
        setPhoneNumber(response?.data?.phoneNumber);
      }
      if (response?.data?.email) {
        setEmailId(response?.data?.email);
      }
      setres(response.data)
      setGpidValid(true);
      setUserValidate(true);
    })
      .then(data => console.log({ data }))
      .catch(error => {
        const originalConfig = error.config;
        console.error('Error fetching data:', error?.response?.data?.message);
        if (error?.response?.status === 403 && !retried) {
          retried = true;
          getChallengeFactors();
        }
        else {
          setError("getChalFac", {
            type: "manual",
            message: error?.message
          });
        }
      });
  }


  const getChildResponse = (type, form) => {
    setSelectedSecurityQuestion(false);
    if (type === 'security') {
      setSelectedOtp(false);

      if (form?.status === 'Failed') {
        setSelectedSecurityQuestion(true);
        if (form?.retryCount) {
          setSecQRetryCount(form?.retryCount);
        }
      }
      else {
        setForgotPwdOptionType(true);
      }
    }
    // if (type === 'otp') {
    //   // sendSmsOtp(form)
    // }
    if (type === 'otp') {
      setSelectedOtp(false);
      // verifyOtpSms(form)
      sendResendSmsOtp('send', 'OTPSMS')
    }
    if (type === 'resendotpsms') {
      sendResendSmsOtp('resend', 'OTPSMS')
    }
    if (type === 'call') {
      setSelectedCall(false);
      // verifyOtpSms(form)
      sendResendSmsOtp('send', 'OTPVOICE', false)
    }
    if (type === 'resendotpcall') {
      sendResendSmsOtp('send', 'OTPVOICE', true)
    }
    if (type === 'email') {
      setSelectedOtp(false);
      // verifyOtpEmail(form)
      // setForgotPwdOptionType(true);

    }
    if (type === 'resendotpEmail') {
      sendResendEmailOtp('resend')
    }
    if (type === 'previous') {
      setSelectedOtp(false);
      setSelectedCall(false);
      setSelectedSecurityQuestion(false);
    }

    if (type === 'otpVerified') {
      setSelectedSecurityQuestion(false);
      if (form?.status === 'Failed') {
        setSelectedOtp(true);
      }
      else {
        setSelectedOtp(false);
        setForgotPwdOptionType(true);
      }
    }
    if (type === 'callVerified') {
      setSelectedSecurityQuestion(false);
      if (form?.status === 'Failed') {
        setSelectedCall(true);
      }
      else {
        setSelectedCall(false);
        setForgotPwdOptionType(true);
      }
    }
  }

  const verifyUser = (type) => {
    // console.log(verifyOption);
    if (type === 'security') {
      setSelectedOtp(false);
      setSelectedSecurityQuestion(true);
    }
    if (type === 'otp') {
      sendResendSmsOtp('send', 'OTPSMS');
      setSelectedSecurityQuestion(false);
      let otpOption = res;
      otpOption.otpType = 'SMS';
      setres(otpOption);
    }
    if (type === 'call') {
      sendResendSmsOtp('send', 'OTPVOICE');
      setSelectedSecurityQuestion(false);
      let otpOption = res;
      otpOption.otpType = 'CALL';
      setres(otpOption);
    }
    if (type === 'email') {
      sendResendEmailOtp('send');
      let otpOption = res;
      otpOption.otpType = 'Email';
      setres(otpOption);
    }
  }

  const goPrevious = () => {
    setGpidValid(false);
  }
  const setVerificationMethod = () => {
    setForgotPwdOptionType(false);
    setSelectedOtp(false);
    setSelectedSecurityQuestion(false);
    setpowdSuccess(false);
  }

  const sendResendSmsOtp = (sendResend, smsCall, resendFlag) => {
    setGpidErrorMessage('');
    let otpRequest = resendFlag !== undefined ? { "gpid": inputValue, "resendOTP": resendFlag } : { "gpid": inputValue };
    axios.post(REACT_APP_API_ENDPOINT + `${sendResend}${smsCall}`, otpRequest).then(response => {
      console.log('response', response.data.responseStatus);
      console.log('response', response.data.responseMessage);

      if (response?.data) {
        let sendOtpRes = response.data;
        if (sendOtpRes.responseStatus === 'Success' && sendOtpRes.responseMessage === 'OTP Sent successfully') {
          setSelectedOtp(true);
          setOtpSent(true);
        }
        else if (sendOtpRes.responseStatus === 'Failed') {
          // Handle this with a message 
          console.log('sendOtpRes.responseStatus', sendOtpRes.responseStatus);
          setErrorMessage(sendOtpRes.responseMessage);
          setSelectedOtp(false);
          setOtpSent(false);
        }
      }
    })
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setGpidErrorMessage(error?.response?.data?.message);
      });
  }

  const getEndpointAccounts = () => {
    let retried = false;
    console.log('Build Environment', REACT_APP_NAME);

    // setGpidErrorMessage('');
    axios.post(REACT_APP_API_ENDPOINT + 'ED9394AC1FD543DAB04DC42D2934C1F5', {
      "gpid": inputValue,
    })
      // .then(response => response.json())
      .then(data => {
        setEndpointAccounts(data?.data?.endpointAccounts)
        console.log(data?.data?.endpointAccounts)
      })
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        // setGpidErrorMessage(error?.response?.data?.message);
        if (error?.response?.status === 403 && !retried) {
          retried = true;
          getEndpointAccounts();
        }
        else {
          setEndpointAccounts([""])
        }
      });
  }


  const sendResendEmailOtp = (sendResend) => {
    setGpidErrorMessage('');
    axios.post(REACT_APP_API_ENDPOINT + `${sendResend}OTPEmail`, {
      "gpid": inputValue
    }).then(response => {
      if (response?.data) {
        let sendOtpEmail = response.data;
        if (sendOtpEmail.status === 'Success' && sendOtpEmail.message === 'Email Sent Successfully') {
          setSelectedOtp(true);
          setOtpSent(true);
        }
        else if (sendOtpEmail.status === 'Failed' && sendOtpEmail.message === 'Not allowed to send SMS now, please try again after 10 minutes') {
          // Handle this with a message 
          setSelectedOtp(false);
          setOtpSent(false);
        }
      }
    })
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setGpidErrorMessage(error?.response?.data?.message);
      });
  }

  const updatePassword = (data) => {
    axios.put(REACT_APP_API_ENDPOINT + 'user/pupdate', {
      "gpid": inputValue,
      "password": encrypt(data.password, publicKey)
    }).then(response => {
      if (response?.data) {
        setGpidErrorMessage('');
        setForgotPwdOptionType(false);
        setSelectedOtp(false);
        setSelectedSecurityQuestion(false);
        let updatePwdResp = response.data;
        if (updatePwdResp.status === 'Success' && updatePwdResp.responseMessage === 'Password update successfully.') {
          setpowdSuccess(true);
          updateSecQRetryCount();
        }
        else if (updatePwdResp.status === 'Failed') {
          // Handle this with a message 
          console.log('updatePwdResp.responseStatus', updatePwdResp.errorMessage);
          setError("updatePassword", {
            type: "manual",
            message: updatePwdResp.errorMessage,
          });
          setForgotPwdOptionType(true);
          setpowdSuccess(false);
        }
      }

    })
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setError("updatePassword", {
          type: "manual",
          message: "An error occured. Please try again later",
        });
        setForgotPwdOptionType(true);
        setpowdSuccess(false);
      });
  }

  const navigate = useNavigate(); // Initialize the navigation hook

  const handleLoginClick = () => {
    navigate('/login'); // Redirect to /login
  };

  const setForgotBg = (value) => {
    if (value === true) {
      setIsBg(true);
    }
    else {
      setIsBg(false);
    }
  }

  const getRetryCount = () => {
    axios.post(REACT_APP_API_ENDPOINT + 'getRetryCount ', {
      "gpid": inputValue
    }).then(response => {
      if (response?.data?.status === 'Success') {
        setSecQRetryCount(response.data.pepmyidMCustomField);
      }
      else {
        setError("secQn", {
          type: "manual",
          message: response?.data?.message,
        })
      }
    }).then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
      });
  }

  const updateSecQRetryCount = () => {
    axios.post(REACT_APP_API_ENDPOINT + 'lockChallengeFactors', {
      "gpid": inputValue,
      retryCount: 0
    }).then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
      });
  }

  return (
    <div id='forgot_password_container'>
      <div className={isBg ? 'pep_forgot_logo_background' : ''}></div>
      {((!selectedOtp && !selectedSecurityQuestion && !isGpidValid) || (isGpidValid && !isUserValidate)) && (
        <div className="card card-layout">
          <div className='idm_logo_background'></div>
          <div>
            <h4>{t('forgot_password_reset')}</h4>

          </div>
          <div className='info-container'>
            <p className='secondary-text'> Type in your User ID</p>
          </div>
          <hr className='custom-hr' />

          <div className='w-1-2'>
            <TextInput type="number" required showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} />
            {errors.getChalFac && <div className='fieldError'>{t(errors.getChalFac.message)}</div>}
            <br /><br />
            <Button className='full-button' disabled={startDisabled} size="medium" text='Start' onClick={isUserValid} />
          </div>
          <Footer />
        </div>
      )}

      {isGpidValid && !selectedOtp && !selectedSecurityQuestion && !isForgotPwdOptionType && !powdSuccess && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='selectionCard'>
            <div style={{ display: 'flex' }}>
              <Button size="small" text="Back" iconLeading='arrow_back' variant="tertiary" onClick={goPrevious} />
            </div>
            <div className='pep_logo_background'></div>
            <div className='powd_name'>
              Forgot Password
            </div>

            <div>
              <div className='secondary-text'>USER ID</div>
              <span className='secondary-text font-700' >{inputValue}</span>
            </div>
            <div className='selectionInfo'>
              <div className='secondary-text' >Verification Method</div>
              <div className='selectionItem' onClick={() => resetTypeSelected('otp')}>
                <div className='selectionSmsIcon'></div>
                <div>{'Text ' + mobileNumber.substring(0, 3) + "*****" + mobileNumber.substring(mobileNumber.length - 3)}</div>
              </div>
              <div className='selectionItem' onClick={() => resetTypeSelected('call')}>
                <div className='selectionCallIcon'></div>
                <div>{'Call ' + phoneNumber.substring(0, 3) + "*****" + phoneNumber.substring(phoneNumber.length - 3)}</div>
              </div>
              <div className='selectionItem' onClick={() => resetTypeSelected('email')}>
                <div className='selectionEmailIcon'></div>
                <div>{'Email ' + emailId.substring(0, 3) + "********" + emailId.substring(emailId.length - 11)} </div>
              </div>
              {secQRetryCount < 3 && (<div className='selectionItem' onClick={() => resetTypeSelected('security')}>
                <div className='selectionSecurityIcon'></div>
                <div>Security Questions</div>
              </div>)}
              {secQRetryCount >= 3 && (<div className='fieldError'>Security Questions has been locked due to multiple wrong answers</div>)}
              {/* ))} */}
            </div>
          </div>
          {errorMessage && <div className='fieldError'>{errorMessage}</div>}

          <Footer />

        </div>
      )}
      {isGpidValid && selectedOtp && !selectedSecurityQuestion && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <OtpForm otpOptions={res} sendDataToParent={getChildResponse} />
          </div>
          <Footer />

        </div>
      )}
      {isGpidValid && selectedCall && !selectedSecurityQuestion && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <OtpForm otpOptions={res} sendDataToParent={getChildResponse} />
          </div>
          <Footer />

        </div>
      )}
      {isGpidValid && selectedSecurityQuestion && !selectedOtp && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <div>
              <h1>Forgot password</h1>
            </div>

            <SecurityForm securityOptions={res} retrySecCount={secQRetryCount} sendDataToParent={getChildResponse} />
          </div>
        </div>
      )}
      {isForgotPwdOptionType && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <div>
              <h1>Forgot password</h1>
            </div>
            <hr className='custom-hr' />
            <div className='endPoints'>
              <div className='accountInfo'>Your password will change for the following systems</div>
              <div className='accountsAlign'>
                {endpointAccounts.map(point => (
                  <span className='endPointInfo'>{point} </span>
                ))}
                {errors.getEndPoints && <div className='fieldError'>{errors.getEndPoints.message}</div>}
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} >
              <div >
                <div style={{ display: 'flex' }}>
                  <TextInput type={type} value={this} {...register('password', { required: 'Password is required' })} showLabelIcon required label={t('New Password')} style={{ width: '-webkit-fill-available' }}
                    onChange={(e) => powdMessageValidation(e.target.value)} />
                  <div className="flex justify-around items-center " id='hide-icon' onClick={handleToggle}>
                    <Icon className="visible" icon={icon} size={16} onClick={handleToggle} />
                  </div>
                </div>
                {errors.password && <div className='fieldError'>{errors.password.message}</div>}
                {passwordValue?.length > 0 && (
                  <div className='passGuides'>
                    <h5>Password Guidelines</h5>
                    <h5>Must:</h5>
                    <li className='hintText' style={{ color: getHintColor(powdHints.minLength || powdHints.hasTwoNumbers) }}>Have at least eight characters ( maximum 14 characters), including two numbers and four letters.</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.hasLowercase) }}>Have at least one lowercase letter( a-z)</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.hasUppercase) }}>Have at least one uppercase letter( A-Z)</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.hasSpecialChar) }}>Contains at least one special character from any of the following values : !^*()_|~`{ }.#[]:";'&lt;	&gt;?,</li>
                    <h5>Must Not:</h5>
                    <li className='hintText'>Match any of the four previous Passwords</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.noRepeatingChars) }}>Have more than two repeating characters or numbers in sequence</li>
                    <li className='hintText'>Use your login name, personal information about yourself (Example: first name, last name, email address)</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.notStartWithNumber) }}>Begin with number as the first character of the password</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.notEndWithNumber) }}>End with number as the last character of the password</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.noSequentialChars) }}>Contain characters or numbers in sequence (Example: abc, xyz, 123)</li>
                    <li className='hintText'>Contains keywords such as year (Example: 2020, 1998), season name, month name</li>
                    <li className='hintText' style={{ color: getHintColor(powdHints.noForbiddenKeywords) }}>Contains keywords such as company or PepsiCo Product name</li>
                  </div>
                )}
              </div>
              <div >
                <TextInput type="password" value={this} {...register('newPowd', {
                  required: 'Retype Password is required',
                  validate: (value) => value === password || "Passwords do not match"
                })} showLabelIcon required label={t('Retype Password')} />
                {errors.newPowd && <div className='fieldError'>{errors.newPowd.message}</div>}
              </div>

              <div>
                <Checkbox label="" size="small" onUpdate={(event) => { onConfirmation(event) }} text="By Clicking this box I agree to the Terms and Conditions" />
              </div>
              <br />
              {errors.updatePassword && <div className='fieldError'>{errors.updatePassword.message}</div>}
              <br />
              <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                <Button size='medium' onClick={setVerificationMethod} >Previous</Button>
                <Button type="submit" size='medium' className="button" disabled={!isValid || !isConfirmed}>Confirm</Button>
              </div>
            </form>
          </div>
        </div>
      )}
      {powdSuccess && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div>
              <h1>Congratulations</h1>
            </div>
            <div className='info-container'>
              <p> You changed your password correctly</p>
            </div>
            <hr className='custom-hr' />
            <div className='powd_success_background'>
            </div>
            <div>
              <Button className='full-button' size="medium" onClick={handleLoginClick}>Continue</Button>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </div >
  );
}

export default ForgotPassword;
