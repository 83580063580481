import React, { useEffect, useState } from 'react';
import { TextInput, Button, Checkbox } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
// import UserValidate from './validate-user';
import forge from "node-forge";
import CryptoJS from 'crypto-js'
import Footer from '../common/footer';
import { useOktaAuth } from "@okta/okta-react"
import { useNavigate } from "react-router";
import './auth.css';

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;
export const encrypt = (plainText, key) => {
  const publicKey = forge.pki.publicKeyFromPem(key);
  return forge.util.encode64(
    publicKey.encrypt(plainText, "RSA-OAEP", {
      md: forge.md.md5.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    })
  )
};

export const encryptAES = (msg) => {
  const key = CryptoJS.lib.WordArray.random(8).toString();
  const iv = CryptoJS.lib.WordArray.random(8).toString();

  // encrypt some bytes using GCM mode
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({
    iv: iv,
    additionalData: 'nvn', // optional
    tagLength: 128 // optional, defaults to 128 bits
  });
  cipher.update(forge.util.createBuffer(msg));
  cipher.finish();
  const encrypted = cipher.output;
  const encodedB64 = forge.util.encode64(encrypted.data);
  const tag = cipher.mode.tag;
  const tagB64 = forge.util.encode64(tag.data);
  return {
    key: encrypt(key, publicKey),
    iv: iv,
    tag: tagB64,
    encrypt: encodedB64,
  }
};

const ChangePassword = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [gpid, setgpid] = useState('');
  const userToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.idToken;
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
  let userGpId = '';
  const { t } = useTranslation();
  const [gpidErrorMessage, setGpidErrorMessage] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [isUserValid, setUserValid] = useState(false);
  const { oktaAuth } = useOktaAuth()
  const history = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [isUpdatePassword, setUpdatePassword] = useState(true);
  const [isBg, setIsBg] = useState(false);

  const handleChange = (event) => {
    console.log(event);
    // let resp= getChallengeFactors();
    console.log('UI Build Test')
    const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
    setInputValue(onlyNumbers);
    if (event.target.value?.length === 8) {

    }
  }
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye)
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  const { register, setValue, handleSubmit, setError, clearErrors, formState: { errors, isValid }, watch } = useForm({
    mode: 'onChange',
    criteriaMode: "all"
  });

  const password = watch("password");

  const onConfirmation = (value) => {
    setIsConfirmed(value);
  }

  const updatePassword = (data) => {
    console.log(data);
    validateUserAndUpdatePassword(data)
  }
  const data = JSON.parse(localStorage.getItem('okta-token-storage'));
  if (!gpid && data?.accessToken?.claims?.gpid) {
    console.log(gpid)
    userGpId = data?.accessToken?.claims?.gpid;
  }
  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem('okta-token-storage'));
  //   console.log(data);
  //   console.log(gpid);
  //   // Set user name from the token storage
  //   if (!gpid && data?.accessToken?.claims?.gpid) {
  //     console.log(gpid)
  //     userGpId = data?.accessToken?.claims?.gpid;
  //     // console.log('Data:', data?.accessToken);
  //     // console.log('Data:', data?.accessToken?.claims);
  //     console.log('Data:', data?.accessToken?.claims?.gpid);
  //     const resGpid = data?.accessToken?.claims?.gpid;
  //     setgpid(resGpid);
  //     setValue('gpid', resGpid);
  //     // const fetchGpid = async () => {
  //     //   const resGpid = data?.accessToken?.claims?.gpid;
  //     //   setgpid(resGpid);
  //     //   setValue('gpid', resGpid);
  //     // }
  //     // fetchGpid();
  //     console.log(gpid)
  //   }
  //   console.log(gpid);

  //   getEndpointAccounts();
  // }, [setValue, gpid]);
  useEffect(() => {
    getEndpointAccounts();
  }, []);
  const validateUserAndUpdatePassword = async (data) => {
    setGpidErrorMessage('');
    try {
      //  await axios.put('http://peplap01100.corp.pep.tst:8080/portalui/api/myidm/user/validateuser', {
      const validateResponse = await axios.post(REACT_APP_API_ENDPOINT + 'user/validate', {
        "gpid": userGpId,
        "usertoken": userToken,
        "apitoken": accessToken
      })
      // .then(response => {
      //   if (response.data.status === "Success") {
      //     setUserValid(true);
      //   }
      //   else {
      //     setUserValid(false);
      //   }
      // }).then(data => console.log({ data }))
      //   .catch(error => {
      //     // Handle error (e.g., display error message)
      //     console.error('Error fetching data:', error?.response?.data?.message);
      //     setGpidErrorMessage(error?.response?.data?.message);
      //     return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      //   });
      if (validateResponse.data.status === "Success") {
        await axios.put(REACT_APP_API_ENDPOINT + 'user/pupdate', {
          "gpid": userGpId,
          "password": encrypt(data.password, publicKey)
        }).then(response => {
          if (response.data.status === "Success") {
            history('/home');
          }
          else if (response.data.status === "Failed") {
            setGpidErrorMessage(response.data.errorMessage);
          }
        }).then(data => console.log({ data }))
          .catch(error => {
            // Handle error (e.g., display error message)
            console.error('Error fetching data:', error?.response?.data?.message);
            setGpidErrorMessage(error?.response?.data?.message);
            return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
          });
      }
      else {
        setGpidErrorMessage(validateResponse.data.message);
        setUserValid(false);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
      setGpidErrorMessage(error?.response?.data?.message || 'An error occured!');
    }
  };

  const powdMessageValidation = (powd) => {
    setIsTyped(true);
    // Base regex for MUST conditions
    const basePattern = /^(?=.*[a-zA-Z]{4,})(?=.*\d{2,})(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)/;

    // Check length, letters, numbers, and special characters
    const isBaseValid = basePattern.test(powd);

    // Additional checks
    const hasLowercase = /[a-z]/.test(powd);
    const hasUppercase = /[A-Z]/.test(powd);
    const hasSpecialChar = /[!^*()_|~`{}.#\[\]:;'"<>?,]/.test(powd);
    const hasTwoNumbers = /\d.*\d/.test(powd);

    // Forbidden keywords
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];
    const noForbiddenKeywords = !forbiddenKeywords.some(keyword => powd.toLowerCase().includes(keyword.toLowerCase()));

    // Sequential characters check (abc, 123)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };
    const noSequentialChars = !isSequential(powd);

    // Repeating characters check (no more than two repeating characters)
    const noRepeatingChars = !/(.)\1{2,}/i.test(powd);

    // Check if it starts or ends with a number
    const notStartWithNumber = !/^\d/.test(powd);
    const notEndWithNumber = !/\d$/.test(powd);

    // Update validation state
    setPowdHints({
      minLength: isBaseValid,
      hasLowercase: hasLowercase,
      hasUppercase: hasUppercase,
      hasSpecialChar: hasSpecialChar,
      hasTwoNumbers: hasTwoNumbers,
      noSequentialChars: noSequentialChars,
      noForbiddenKeywords: noForbiddenKeywords,
      noRepeatingChars: noRepeatingChars,
      notStartWithNumber: notStartWithNumber,
      notEndWithNumber: notEndWithNumber,
    });
  };
  const [isTyped, setIsTyped] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [endpointAccounts, setEndpointAccounts] = useState(['myidM', 'IDX', 'PKI']);

  const [powdHints, setPowdHints] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasTwoNumbers: false,
    noSequentialChars: true,
    noForbiddenKeywords: true,
    noRepeatingChars: true,
    notStartWithNumber: true,
    notEndWithNumber: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    const powdError = powdValidation(data.password);
    if (powdError !== true) {
      setError("password", {
        type: "manual",
        message: powdError
      });
      return;
    }
    updatePassword(data);
  }
  const powdValidation = (password) => {
    // Regex pattern for the MUST requirements
    const pattern = /^(?!.*(.)\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*\d)(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)(?!^\d)(?!.*\d$)/;

    // Checking the password against the regex pattern
    if (!pattern.test(password)) {
      return "Password does not meet the basic criteria.";
    }

    // Additional checks for MUST NOT conditions
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];

    // Check for forbidden keywords
    for (const keyword of forbiddenKeywords) {
      if (password.toLowerCase().includes(keyword.toLowerCase())) {
        return `Password must not contain forbidden keywords like ${keyword}`;
      }
    }

    // Check for sequential characters (abc, 123, etc.)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };

    if (isSequential(password)) {
      return "Password must not contain sequential characters or numbers.";
    }

    return true; // Password is valid
  };
  const getHintColor = (conditionMet) => {
    // If user hasn't typed yet, default to gray, otherwise red or green based on condition
    return isTyped ? (conditionMet ? 'green' : 'red') : '#9e9e9e';
  };
  const setVerificationMethod = () => {
    // setForgotPwdOptionType(false);
    // setSelectedOtp(false);
    // setSelectedSecurityQuestion(false);
    // setPasswordSuccess(false);
  }

  const getEndpointAccounts = () => {
    // setGpidErrorMessage('');
    axios.post(REACT_APP_API_ENDPOINT + 'ED9394AC1FD543DAB04DC42D2934C1F5', {
      "gpid": userGpId,
    })
      // .then(response => response.json())
      .then(data => {
        setEndpointAccounts(data?.data?.endpointAccounts)
        console.log(data?.data?.endpointAccounts)
      })
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error);
        setGpidErrorMessage(error?.response?.data?.message);
        return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }

  const setChangeBg = (value) => {
    if (value === true) {
      setIsBg(true);
    }
    else {
      setIsBg(false);
    }
  }
  return (
    <div id='change_password_container'>
      <div className={isBg ? 'pep_forgot_logo_background' : ''}></div>
      {isUpdatePassword && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <div>
              <h4>Change Password</h4>
            </div>
            {/*  
           <div className="card card-layout reg-card">
              <div className='card-content'>
                <div className='idm_logo_background'></div>
                <div className='info-container'>
                  <p> You're all set! Please select your new password</p>
                </div>
                */}
            <div className='endPoints'>
              <div className='accountInfo'>Your password will change for the following systems</div>
              <div className='accountsAlign'>
                {endpointAccounts?.map((point, index) => (
                  <span key={index} className='endPointInfo'>{point} </span>
                ))}
              </div>
            </div>
            <hr className='custom-hr' />
            <form onSubmit={handleSubmit(onSubmit)} >
              <div >
                <TextInput type="password" value={this} {...register('oldPassword', {
                  required: 'Old Password is required',
                })} showLabelIcon required label={t('Old password')} />
                {errors.oldPassword && <div className='fieldError'>{errors.oldPassword.message}</div>}
                <div style={{ display: 'flex' }}>
                  <TextInput type={type} value={this} {...register('password', { required: 'Password is required' })} showLabelIcon required label={t('New Password')} style={{ width: '-webkit-fill-available' }}
                    onChange={(e) => powdMessageValidation(e.target.value)} />
                  <div className="flex justify-around items-center " id='hide-icon' onClick={handleToggle}>
                    <Icon className="visible" icon={icon} size={16} onClick={handleToggle} />
                  </div>
                </div>
                {errors.password && <div className='fieldError'>{errors.password.message}</div>}
                <div className='passGuides'>
                  <h5>Password Guidelines</h5>
                  <h5>Must:</h5>
                  <li className='hintText' style={{ color: getHintColor(powdHints.minLength || powdHints.hasTwoNumbers) }}>Have at least eight characters ( maximum 14 characters), including two numbers and four letters.</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.hasLowercase) }}>Have at least one lowercase letter( a-z)</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.hasUppercase) }}>Have at least one uppercase letter( A-Z)</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.hasSpecialChar) }}>Contains at least one special character from any of the following values : !^*()_|~`{ }.#[]:";'&lt;	&gt;?,</li>
                  <h5>Must Not:</h5>
                  <li className='hintText'>Match any of the four previous Passwords</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.noRepeatingChars) }}>Have more than two repeating characters or numbers in sequence</li>
                  <li className='hintText'>Use your login name, personal information about yourself (Example: first name, last name, email address)</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.notStartWithNumber) }}>Begin with number as the first character of the password</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.notEndWithNumber) }}>End with number as the last character of the password</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.noSequentialChars) }}>Contain characters or numbers in sequence (Example: abc, xyz, 123)</li>
                  <li className='hintText'>Contains keywords such as year (Example: 2020, 1998), season name, month name</li>
                  <li className='hintText' style={{ color: getHintColor(powdHints.noForbiddenKeywords) }}>Contains keywords such as company or PepsiCo Product name</li>
                </div>
              </div>
              <div >
                <TextInput type="password" value={this} {...register('newPassword', {
                  required: 'Retype Password is required',
                  validate: (value) => value === password || "Passwords do not match"
                })} showLabelIcon required label={t('Retype Password')} />
                {errors.newPassword && <div className='fieldError'>{errors.newPassword.message}</div>}
              </div>
              <div>
                <Checkbox label="" size="small" onUpdate={(event) => { onConfirmation(event) }} text="By Clicking this box I agree to the Terms and Conditions" />
              </div>
              <br /><br />
              <Button type="submit" size='medium' className='full-button' disabled={!isValid || !isConfirmed}>Confirm</Button>
              {gpidErrorMessage && <div className='fieldError'>{gpidErrorMessage}</div>}
            </form>

            {/*  </div>
            </div>*/}
            {/* <p> <b>After changing your password, follow the appropriate instructions to login</b>
               <div class="a"><font color="#000066" face="Verdana, Helvetica, sans-serif" size="2"><u><b>Connected to PepsiCo's network or PepsiCo's Virtual Private Network (VPN),</b></u></font> you can use your new password to log in to both your computer and the PepsiCo systems and applications.</div><br/><br/>
              <div class="a"><font color="#000066" face="Verdana, Helvetica, sans-serif" size="2"><b><u>Not connected to PepsiCo's network or PepsiCo's VPN,</u></b> </font> you will maintain two passwords until the next time you are connected to the PepsiCo network, either at a PepsiCo location or on VPN.&nbsp;You will need to use your old password to log in to your computer and your new password to log in to PepsiCo systems and applications.</div>
              </p>
             <hr className='custom-hr' />
            <form onSubmit={handleSubmit(updatePassword)} style={{ display: 'contents' }} >
              <div className='pwd-form-group' style={{ display: 'inline-flex' }}>
                <TextInput type="text" readOnly value={gpid} {...register('gpid')} showLabelIcon required label={t('GPID')} />
              </div>
              <div style={{ display: 'flex' }}>
                <TextInput type={type} value={this} {...register('password', { required: 'Password is required' })} showLabelIcon required label={t('New Password')} style={{ width: '-webkit-fill-available' }} />
                <div className="flex justify-around items-center" onClick={handleToggle}>
                  <Icon className="visible" icon={icon} size={16} onClick={handleToggle} />
                </div>
                {errors.password && <div className='fieldError'>{errors.password.message}</div>}
              </div>
              <div className='pwd-form-group' style={{ display: 'inline-flex' }}>
                <TextInput type="password" value={this} {...register('newPassword', { required: 'Retype Password is required' })} showLabelIcon required label={t('Retype Password')} />
                {errors.newPassword && <span>{errors.newPassword.message}</span>}
              </div>
              <div>
                <div>Your password will change for the following systems</div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                  <table className='borderLessTable'>
                    <tr style={{ textAlign: 'left' }}>
                      <th>User ID</th>
                      <th>System Name</th>
                    </tr>
                    <tr>
                      <td className='text-left'>123456789</td>
                      <td className='text-right'>MyIDM</td>\
                      \
                    </tr>
                    <tr>
                      <td className='text-left'>123456707</td>
                      <td className='text-right'>IDX</td>
                    </tr>
                  </table>
                </div>
                <div>
                  <Checkbox label="" size="small" text="By Clicking this box I agree to the Terms and Conditions" />
                </div>
                <br /><br />
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Button className='full-button' size="medium" text='Confirm' type="submit" />
                </div>
              </div>
            </form> */}
          </div>
        </div >
      )
      }
      <Footer />
    </div >
  );
}

export default ChangePassword;
